@import "../_core/scss/variables/variables";

.beatingHeartJourney {
  display: flex;

  .beatingHeart {
    margin-left: -50px;
    width: 100px;
    min-width: 0;

    &.active {
      transform: scale(1.3);
      transform-origin: bottom;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
