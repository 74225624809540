/**
DO NOT EDIT OUTSIDE OF ICARUS.
 */
#pageContent {
  #pageContentInner {
    .page-no-layout {
      justify-content: center;
      align-items: center;
      display: flex;
      > .row{
        padding: 30px;
        background: none;
        border-radius: 10px;
      }
      .imageTile {
        text-align: center;
        margin-bottom: 40px;
        img{
          max-width: 60%;
        }
      }
      .vue-form {
        text-align: center;
        .formTitle{
          color: $c-white;
          margin-bottom: 40px;
          h5 {
            font-size: 1.1rem;
            font-weight: 400;
          }
        }
        .form-group {
          label {
            display: none;
          }
          input {
            text-align: center;
          }
        }
      }
    }
  }
}
