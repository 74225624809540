@import "../_core/scss/variables/variables";

.buildNumber {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: transparentize($brand--c1, 0.2);
  border-top-left-radius: 6px;
  color: #fff;
  min-width: 100px;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.2);
  padding: 6px;

  &__close {
    color: #f00;
    position: absolute;
    left: -14px;
    top: -13px;
    padding: 0;

    .fa-stack {
      font-size: 0.6em;
    }

    .fa-circle {
      font-size: 1.4em;

    }

    .fa-times {
      color: #fff;
      line-height: 1.6em;
    }

    &:hover {
      color: #f00;

      .fa-circle {
        color: darken(#f00, 10%);
      }
    }
  }

  &__label {
    font-weight: bold;
    margin-right: 3px;
  }
}
