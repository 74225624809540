@import "../../scss/variables/variables";

  .progressbar-wrapper {
    padding: 40px 0;
  }
 .progressbar-labels {
   padding: 8px 0;
   font-size: 1.1rem;
   font-weight: bold;
 }
 .progress {
   height: 30px;
 }
