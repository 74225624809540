@import "../../../_core/scss/variables/variables";

.companyBeatingHeartPanel {
  &__inner {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &__heart {
    width: 50%;
    min-width: 175px;
  }

  .beatingHeartJourney {
    margin-top: .5em;
  }

}
