/**
Basic Form Styles
 */
.vue-form {
	.form-group {
		margin-bottom: $form--input--margin--bottom;
		.multiselect__select {
			display: flex;
			align-items: center;
		}
		.multiselect__tags,
		input {
			text-align: left;
			padding: $form--input--padding-top $form--input--padding-right
				$form--input--padding-bottom $form--input--padding-left;
		}
		.multiselect {
			&--active {
			}
			&__select {
				height: 100%;
				z-index: 9;
				place-content: center !important;
				&:before {
					top: unset;
					color: $text-color;
					margin-top: 0;
					border-color: unset;
					border-style: unset;
					border-width: unset;
					font-family: "Font Awesome 5 Pro";
					content: "\f078";
					align-content: center;
					align-items: center;
				}
			}
			&__tags {
				.focus-visible {
					padding: 0;
				}
			}
			&__placeholder {
				margin: 0;
				font-size: $text--body--size;
				padding: 0;
			}
			&__single {
				padding: 0;
				margin: 0;
			}
		}
		.form-check-wrapper {
			display: flex;
			.form-check {
				display: flex;
				align-items: center !important;

				input {
					cursor: pointer;
					margin-right: 10px;
				}
			}
		}
	}

	.formButtons {
		button {
			width: 100%;
			height: $form--button-height;
			margin-bottom: 15px;
		}
		&.with-clear {
			button {
				width: 45%;
				margin-left: 2%;
				margin-right: 2%;
			}
		}
	}
}

.form-switch {
	.form-check-input {
		margin-left: 0;
		margin-top: 4px;
		margin-right: 10px;
		background-color: $brand-danger;
		&:checked {
			background-color: $brand--c1;
		}
	}
}

/**
Filter Form Styles
 */
form {
	&.filter-form {
		background: $brand--c1;
		padding: 20px;
		margin-bottom: 20px;
		&.horizontal {
			.form-group {
				margin-bottom: 0;
			}
		}
		.form-title {
			border-radius: 5px 5px 0 0;
			color: $brand--c1--hover-text;
			padding: 10px;
			span {
				font-weight: bold;
			}
		}
		label {
			color: $brand--c1--hover-text;
		}
		.formButtons {
			display: flex;
			place-content: end;
			button {
				flex: 1 50%;
				height: $form--button-height;
				margin-bottom: 15px;
				background: $brand--c2;
				&:hover {
					background: $brand--c2--hover;
					color: $brand--c2--hover-text;
				}
			}
			&.with-clear {
				button {
					width: 45%;
					margin-left: 2.5%;
					margin-right: 2.5%;
				}
			}
		}
	}
}

.housekeeping-and-cleanliness,
.truck-operator-check,
.uniform-order {
	form {
		.form-group {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			margin-bottom: 2rem;
			.form-field-container {
				flex-basis: 49%;
			}
			legend {
				font-weight: 400;
			}
		}
	}
}

.damage-report {
	form {
		.form-group {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			margin-bottom: 2rem;
			.form-field-container {
				flex-basis: 49%;
			}
			.radiogroup-field-container {
				flex-basis: 100%;
				label {
					display: block;
				}
				.form-check {
					display: inline-block;
					width: 50%;
				}
			}
			legend {
				font-weight: 400;
			}
		}
	}
}

.see-sort-report,
.visitor-report,
.create {
	form {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		.form-fieldset,
		.form-field-container {
			flex-basis: 49%;
		}
	}
}

.create {
	form {
		.formButtons {
			width: 100%;
		}
	}
}

// login
.login {
	.page-no-layout {
		max-width: 60%;
		margin: auto;
	}
	.login-form {
		margin-bottom: 2rem;
		form {
			display: flex;
			flex-wrap: wrap;
			width: 75%;
			margin: auto;
			gap: 10px;
			.form-field-container {
				flex-basis: 100%;
				.form__label {
					color: #fff;
					text-shadow: 2px 1px #2b2b2b;
					font-weight: 400;
				}
			}
			.formButtons {
				width: 100%;
				.btn.btn-primary:hover {
					background-color: $orange;
					border: 0;
				}
			}
		}
	}
	.forgottenPasswordLink,
	.registerLink {
		width: 50%;
		display: inline-block;
		text-shadow: 2px 1px #2b2b2b;
		font-weight: 400;
		color: #fff;
	}
	.col {
		display: none;
	}
	.btn.btn-primary.basic-link {
		margin: 2rem auto 0;
		width: fit-content;
		&:hover {
			text-decoration: none;
		}
	}
}

.register {
	.loginLink {
		text-shadow: 2px 1px #2b2b2b;
		font-weight: 400;
		color: #fff;
		margin-top: 1rem;
	}
}
