//text variables
//font imports:

//Headers
//defaults
$font-family-sans-serif: 'Roboto', 'Quicksand', sans-serif !default;

//Specific headers
$text--header--1--color: $brand--c1 !default;
$text--header--1--font: $font-family-sans-serif !default;

//body
$text-color: #2b2d2e !default;
$text--body--size: 1rem !default;
$text--body--font: $font-family-sans-serif !default;