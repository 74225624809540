@import "../../scss/variables/variables";

#topBar {
	background: $structure--topbar--background;
	padding: 10px;
	width: 100%;
	height: 80px;
	-webkit-box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.25);
	box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.25);

	.row {
		margin: 0;
		height: 100%;

		img {
			height: 100%;
		}
	}

	.page-details {
		h2 {
			color: $brand--c1--hover-text;
			font-size: 35px;
			font-weight: 700;
		}
	}

	.account {
		float: right;

		img {
			float: left;
			margin-right: 10px;
		}
	}

	@media (max-width: 1200px) {
		.horizontal-nav {
			width: calc(100% - 220px - 15vw);
		}
	}
}

//If we have content inner etc, we need to override them
#content-wrapper {
	#content-inner {
		> div {
			&#topBar {
				padding: 14px;
			}
		}
	}
}
