@import "../_core/scss/variables/variables";

.historyList {
  &__items {
    list-style: none;
    padding: 0;
  }

  &__date {
    font-style: italic;
  }

  &__action {
    font-weight: bold;
  }

}
