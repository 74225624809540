//Structure Variables
$structure--html--background: url('@assets/backgrounds/icarus.webp') !default; // @todo push this into a system var and set with globals call.
$structure--html--background--auth: #e7e7e7 !default;

$structure--scrollbar--width: 10px !default;
$structure--scrollbar--height: 9px !default;

$structure--scrollbar--track: rgba(0, 0, 0, 0.15) !default;
$structure--scrollbar--handle: rgba(0, 0, 0, 0.15) !default;
$structure--scrollbar--hover: lighten($brand--c1, 20%) !default;

//content vars
$structure--content--flex--gap: 15px !default;
$structure--content--background: $c-white !default;

//core components
$structure--topbar--background: $c-white !default;
$structure--leftsidebar--background: $brand--c1 !default;
