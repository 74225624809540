@import "../../scss/variables/variables";

html{
  &.right-sidebar-open{
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  }
}
#rightSidebar{
  .opener{
    color: $c-white;
    cursor: pointer;
    padding: 10px;
    font-size: 25px;
    text-align: right;
  }
  .sidebar-wrapper {
    display: none;
    position: absolute;
    right: -500px;;
    top: 0;
    width: 500px;
    max-width:100%;
    flex: 0 0 500px;
    background: $brand--c1;
    animation: slide 0.5s forwards;
    animation-delay: 0s;
    &.open{
      display: flex;
    }
    .closer-wrapper {
      position: absolute;
      right: 0;
      .closer {
        color: $brand--c1--hover-text;
        padding: 20px;
        font-size: 25px;
        cursor: pointer;
      }
    }
    @keyframes slide {
      100% { right: 0; }
    }
    .menu{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      .group {
        width: 100%;
        text-align: center;
        &:hover{
          background: none;
        }
        a{
          color: $brand--c1--hover-text;
          &:hover{
            color: $brand--c2;
          }
        }
      }
    }
  }
}
