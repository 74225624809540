//padding
$form--input--padding-top: 15px !default;
$form--input--padding-right: 15px !default;
$form--input--padding-bottom: 15px !default;
$form--input--padding-left: 15px !default;

//margins
$form--input--margin--bottom: 20px !default;

//buttons
$form--button-height: 55px !default;

//errors
$form--error--color: rgb(220, 86, 86);