nav {
  ul {
    &.pagination {
      li {
        span{
          &.active{
            background: $brand--c1;
            color: $brand--c1--hover-text;
          }
        }
      }
    }
  }
}