@import "../_core/scss/variables/variables";

.itemSelector {
  padding: 12px;
  position: fixed;
  right: 0;
  top: 200px;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 200ms ease-in-out;
  min-height: 200px;
  min-width: 200px;

  &__items {
    list-style: none;
    padding: 0;
    pointer-events: none;
  }

  &--in {
    transform: none;

    .itemSelector__items {
      pointer-events: all;
    }
  }

  &__handle {
    font-weight: bold;
    position: absolute;
    padding: 12px 3px;
    top: 0;
    bottom: 0;
    color: white;
    cursor: pointer;
    background-color: blue;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 26px;
    right: 100%;
  }

  &__title {
    transform: rotate(90deg);
  }

  .fa {
    color: blue;
    margin-right: 6px;
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }
}
