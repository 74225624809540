@import "../../scss/variables/variables";


.icarus-modal-btn .btn {
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.icarus-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  text-align: center;
}


.icarus-modal-overlay:before {
  content:"";
  display:inline-block;
  width:0;
  height:100%;
  vertical-align:middle;
}

.icarus-modal-overlay.active {
  visibility: visible;
  opacity: 1;
}

.icarus-modal-content {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 1000;
  display:inline-block;
  vertical-align:middle;
  text-align: left;
}

.icarus-modal-content h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.icarus-modal-content .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.icarus-modal-content .close:hover {
  color: #06D85F;
}
.icarus-modal-content .icarus-modal-body {
  max-height: 50%;
  overflow: auto;
}

@media screen and (max-width: 700px){
  .icarus-modal-btn{
    width: 70%;
  }
  .icarus-modal-content{
    width: 70%;
  }
}

