@import "../../scss/variables/variables";

.messages {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 11;

  .toast {
    display: block;
    position: relative;
    width: 400px;
    padding: 10px 10px 10px 20px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    --toast-color: #{$brand--c1};
    border: 1px solid var(--toast-color);
    color: var(--toast-color);

    &--success {
      --toast-color: #{$brand-success};
    }

    &--error {
      --toast-color: #{$brand-danger};
    }

    &__timeout {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 5px;
      background-color: var(--toast-color);
      animation: timeout;
      animation-duration: var(--toast-timeout);
      animation-timing-function: linear;

      @keyframes timeout {
        from {
          right: 100%;
        }

        to {
          right: 0;
        }
      }
    }

    button {
      appearance: none;
      right: 20px;
      position: absolute;
      background: #fff;
      border: none;

      &:hover {
        color: $brand-danger;
      }

      button {

      }
    }
  }

}
