button:disabled,
button[disabled="true"] {
	background: #6d6d6d;
}

.btn {
	&.btn-primary {
		color: $button--primary--text--color;
		background-color: $orange;
		border: 0;
		&:hover {
			background-color: $c-dark-blue;
			border-color: $button--primary--color--hover;
		}
	}
}
