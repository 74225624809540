@import "../_core/scss/style";

#content-wrapper {
	background-color: #fff;
}

#content-inner {
	padding-top: 20px;
}

.simple-dropdown-nav__admin {
	//display: none!important;
}

#leftSidebar {
	.account {
		position: relative;
		overflow: hidden;
		height: 9.53rem;
		background-image: url(@assets/backgrounds/warehouse-background.jpg) !important;
		background-size: cover;

		.user-role {
			color: $c-white;
		}

		.user-details div {
			display: inline-block;
		}

		.user-name {
			width: 100%;
		}
	}
}

.icarus-card-tile {
	margin-bottom: 15px;
	.card-body {
		position: relative;
		.table {
			table-layout: initial !important;
			overflow: auto;
		}
	}

	.card-header {
		display: flex;
		justify-content: space-between !important;
		align-items: center;
		min-height: 40px;

		h3 {
			margin-bottom: 0;
			font-size: 1.4rem;
			padding: 4px 0;
		}
	}
}

.administration-menu {
	display: none;
}

html {
	background-image: url(@assets/backgrounds/warehouse-background.jpg);
	background-size: cover;
	background-position: center center;
}

.login.wrapper {
	#thePageContent {
		height: 100%;
		#thePageContent__inner {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			.page-no-layout {
				padding: 30px;
				border-radius: 15px;
				background-color: #072b60;
				.imageTile {
					img {
						margin: 20px 30px 30px 30px;
					}
				}
				.login-form {
					h5 {
						color: #fff;
					}
					max-width: 600px;
				}
			}
		}
	}
}

.dashboard {
	.menu-tile-wrapper {
		width: 100% !important;
	}
}

.user,
.role {
	#content-wrapper {
		max-width: calc(100% - 200px);
		.btn.btn-primary.basic-link {
			margin: 0 20px;
		}
		.card {
			margin: 20px;
		}
	}
}

.user {
	.card {
		.card-body {
			overflow: scroll;
		}
	}
}

.create {
	.data-tile {
		margin: 10px;
		width: 100%;
	}
}
