@import "../_core/scss/variables/variables";

.dashboardPanel {
  $self: &;

  height: 100%;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .6);

  &--noChrome {
    box-shadow: none;
  }

  &__header {
    display: flex;
    height: 38px;
    align-items: center;
    padding: 0 0 0 12px;

    background-color: #333333;
    color: #ffffff;
    font-weight: bold;
  }

  &__actions {
    margin-left: auto;

    .btn-link {
      color: #ffffff;

      &:hover {
        color: #999999;
      }
    }
  }

  &__contents {
    height: calc(100% - 38px);
    padding: 12px;
    background-color: #fff;
    overflow: scroll;
    cursor: auto;

    #{ $self }--noChrome > & {
      padding: 0;
    }
  }

  &--empty {
    .dashboardPanel__contents {
      height: 30px;
    }
  }

  &--minimized {
    .dashboardPanel__contents {
      display: none;
    }
  }
}
