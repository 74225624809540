@import "../../scss/variables/variables";

table {
  &.table {
    table-layout: fixed;

    tbody {
      tr {
        background: none;
      }
    }

    td {
      word-wrap: break-word;
      white-space: normal;
      padding: $table--cell--padding;
      font-size: $table--cell--size;
    }
  }
}
