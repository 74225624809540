@import "../../scss/variables/variables";

.loadingWrapper {
  padding: 10px;
  z-index: 999;
  color: $c-black;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.5);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);

  &__text {
    margin-bottom: 12px;
  }
}
