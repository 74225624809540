@import "../../scss/variables/variables";

.generalFormMessages {
  &.error {
    .message {
      margin-bottom: 20px;
      background: $form--error--color;
      padding: 10px;
      color: $brand--c1--hover-text;
    }
  }
}

form {
  .form-input--error {
    outline-color: $form--error--color;
    outline-style: solid;
  }
}

.form-submit {
  &.loader-spinner {
    width: 20px;
  }
}

.hidden-field-wrapper {
  display: none;
}
input.form-input--error {
  border: 1px solid #ff0000;
}
span.text-error {
  color: #ff0000;
}

