@import "../_core/scss/variables/variables";

.trafficLightDisplay {
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.6);

  > * {
    padding: 1rem;
  }

  .big {
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.6);;
  }

  &__content {
    border-width: 2px 0;
    border-style: solid;
    border-color: #fff;
  }
}
