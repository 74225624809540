@import "../../scss/variables/variables";

.icarus-dropmenu {
  position: relative;
  overflow: visible;
  .icarus-dropmenu-items {
    position: absolute;
    right: 100%;
    top:0;
    padding: 7px 30px 7px 10px;
    background-color: #fff;
    border-radius: 5px;
    border-top-right-radius: 0;
    li {
      list-style-type: none;
      padding: 5px 0;
      a {
        font-weight: bold;
      }
    }
  }
  .icarus-dropmenu-toggle:not(.expanded) + .icarus-dropmenu-items {
    display: none;
  }
  .icarus-dropmenu-toggle.expanded + .icarus-dropmenu-items {
    display: block;
    z-index: 1000;
  }
  .icarus-dropmenu-toggle {
    padding-right: 10px;
    position: relative;
    &.expanded {
      background-color: #fff;
      border-radius: 20px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 1000;
    }
  }
}
