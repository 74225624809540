/**
 * SCROLLBAR
 */
::-webkit-scrollbar {
	width: $structure--scrollbar--width;
	height: $structure--scrollbar--height;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
	display: block;
	height: 0;
	background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track-piece {
	background-color: $structure--scrollbar--track;
	border-radius: 0;
	border-left: 1px solid $c-white;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $structure--scrollbar--handle;
	border-radius: 0;
	border-left: 1px solid $c-white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $structure--scrollbar--hover;
}

::-webkit-scrollbar-thumb:hover {
	background-color: $structure--scrollbar--hover;
}

html {
	background-image: $structure--html--background;
	background-size: cover;
	min-height: 100vh;
	&.authenticated {
		background: $structure--html--background--auth;
	}
	body {
		background: none;
		min-height: 100vh;
		> div {
			&.wrapper {
				min-height: 100% !important;
			}
		}
	}
}

/**
 * CUSTOM UTILITY CLASSES
 */

.p-top-0 {
	padding-top: 0 !important;
}
.p-left-0 {
	padding-left: 0 !important;
}
.p-right-0 {
	padding-right: 0 !important;
}
.p-bottom-0 {
	padding-bottom: 0 !important;
}

.wrap-anywhere {
	word-wrap: anywhere;
}
/**
 * LINKS
 */

a {
	color: $link--c1;
	&:active {
		color: $link--c1--active;
	}
	&:hover {
		color: $link--c1--hover;
	}
}

/**
 * TABLES
 */

table {
	table-layout: auto;
	tbody {
		tr {
			background: none;
			&:hover {
				background: $brand--c1;
				color: $brand--c1--hover-text;
				cursor: pointer;
			}
		}
	}
	td {
		padding: $table--cell--padding;
		font-size: $table--cell--size;
	}
	&.table {
		&.table-responsive {
			display: table;
		}
		tbody {
			tr {
				&:hover {
					background: lighten($brand--c1, 40%);
					color: $brand--c1--hover-text;
					cursor: pointer;
				}
				td {
					padding: 0.25rem;
					word-wrap: break-word;
					white-space: normal;
					button {
						padding: 5px;
						margin-bottom: 0;
					}
					&:first-child {
						font-weight: bolder;
					}
				}
			}
		}
	}
}

/**
 * TOP BAR PER PAGE STYLES
 */
.page-top-menu {
	#topBar {
		padding: 5px;
	}
}

/**
 * DIV Tables
 */
.table__div {
	position: relative;
	&__table-header {
		background: $brand--c1;
		color: $brand--c1--hover-text;
		font-weight: 500;
	}
	&__table-row {
		align-items: center;
		padding-top: 8px;
		padding-bottom: 8px;
	}
	&__table-body {
		.table__div__table-row {
			background: $c-white;
			margin: 5px 0;
			box-shadow: 0 2px 2px 0 #0000003d;
			border-top: 1px solid #00000012;
		}
	}
	&__table-cell {
		font-size: 0.9rem;
		&__header {
			display: none;
			text-align: left;
			font-weight: bold;
		}
	}
	&__col {
		&__components {
			text-align: right;
			button {
				margin-right: 10px;
			}
		}
	}
	&.tiled {
		.table__div__table-header {
			display: none;
		}
		.table__div__table-cell {
			display: flex;
			flex: 1 50%;
			padding-top: 2px;
			padding-bottom: 2px;
			align-items: center;
			font-size: 0.8rem;
			&__header {
				display: block;
				flex: 1 50%;
			}
			&__content {
				text-align: right;
				//flex: 1 50%;
			}
			@include media-breakpoint-down(sm) {
				flex: 1 100%;
			}
		}
		.table__div__col {
			&__components {
				flex: 1 100%;
				place-content: center !important;
				margin-top: 10px;
			}
		}
	}

	.table {
		&__btn-eye {
			background: lighten($brand--c1, 55%);
			&:hover {
				background: lighten($brand--c1, 35%);
			}
		}
		&__btn-edit {
			background: lighten(orange, 45%);
			&:hover {
				background: lighten(orange, 25%);
			}
		}
		&__btn-trash {
			background: lighten(red, 45%);
			&:hover {
				background: lighten(red, 25%);
			}
		}
	}
}
/**
 * PAGINATION
 */
.pagination-wrapper {
	margin-bottom: 20px;
	.row {
		align-items: center;
		p,
		ul {
			margin: 0;
		}
	}
}

.secondary-components {
	#topBar {
		margin-bottom: 2rem;
		.row {
			padding: 0;
			.title-tile {
				padding: 0;
				> span {
					display: flex;
					align-items: center;
				}
			}
		}
	}
}
