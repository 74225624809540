#content-wrapper {
  padding: 0;
  flex: 1;
  #content-inner{
    > div {
      padding: 30px;
      flex: 1;
      gap: $structure--content--flex--gap;
      > div {
        align-content: normal;
      }
      &.content-no-bg{
        div {
          background: transparent; //override the background above
        }
      }
    }
    div {
      place-content: flex-start;
    }
    .content-wrapper{
      padding: 20px;
      background: $structure--content--background;
      overflow-wrap: break-word;
      position: relative;
    }
  }
}