//Colours
$brand--c1: #012e58 !default;
$brand--c2: #007acc !default;

//Messaging
$brand-danger: #ff8d8d !default;
$brand-warning: #d9ad2b !default;
$brand-success: #66f171 !default;

$brand--c1--border: $brand--c1 !default;
$brand--c2--border: $brand--c2 !default;

//hovers
$brand--c1--hover: darken($brand--c1, 20%) !default;
$brand--c2--hover: darken($brand--c2, 20%) !default;
$brand--c1--border--hover: $brand--c1--hover !default;
$brand--c2--border--hover: $brand--c2--hover !default;

//text overlays
$brand--c1--hover-text: #fff !default;
$brand--c2--hover-text: #fff !default;