@import "../../scss/variables/variables";

.basic-link {
  text-align: left;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
