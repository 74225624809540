@import "../../_core/scss/variables/variables";

@import "tabulator-tables/dist/css/tabulator_bootstrap5.min.css";

.tabulator {
  font-size: 0.9em;
  box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.25);
}

.generalSearch {
  margin-bottom: 1em;

  &__label {
    display: block;
    font-weight: bold;
  }

  &__input {
    border-radius: .25rem;
  }
}
