@import "../_core/scss/variables/variables";

.tabFilter {
  &__filters {
    margin-bottom: 0;

  }

  &__filter {
    background-color: #6e7677;
    margin-right: 0 !important;
    border-right: 3px solid #888f90;

    &:last-child {
      border-right: none;
    }

    .btn-link {
      text-decoration: none;
      color: #fff;
    }

    &.tabFilter--active {
      background-color: #565656;
    }
  }

}
